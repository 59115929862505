<template>
  <b-row class="d-flex align-items-center">
    <b-col>
      <b-input-group>
        <b-form-input
          v-model="searchKeyword.keyword"
          placeholder="Firma Adı, Telefon, Vergi No..."
          @keydown.enter="searchAction"
        />
        <b-input-group-append>
          <b-button
            variant="primary"
            @click="searchAction"
          >
            <FeatherIcon icon="SearchIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Search',
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  props: {
    searchAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    searchKeyword() {
      return this.$store.getters['dealers/search']
    },
  },
}
</script>

<style scoped>

</style>
